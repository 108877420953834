<template>
  <el-row
    align="middle"
    justify="center"
    style="min-height: calc(100vh - 57px)"
  >
    <el-col
      :span=6
      class="card-login"
    >
      <el-row>
        <img style="width: 100%" alt="Vue logo" src="../assets/bg-login.png">
      </el-row>
      
      <!-- <el-divider >
        <img class="logo" alt="Vue logo" src="../assets/logo.png">
      </el-divider> -->
      <el-row
        class="box-form"
        justify="center"
      >
        <el-col :span=16>

          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="rules"
            @submit.prevent="submitForm"
          >
            <el-form-item prop="mail">
              <el-input
                v-model="loginForm.mail"
                placeholder="Informe seu e-mail"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="default"
                style="background: black; color: #fff300;"
                @click="submitForm"
              >
                Acessar
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
          <el-alert
            v-if="formError"
            title="E-mail não cadastrado!"
            type="error"
            effect="dark"
            closable=false
            show-icon
          >
          </el-alert>
          <el-alert
            v-if="linkSended"
            title="Link enviado com sucesso"
            description="Verifique seu e-mail para completar a autenticação"
            type="success"
            effect="dark"
            closable=false
            show-icon
          >
          </el-alert>
      </el-row>
    </el-col>
  </el-row>
</template>


<style scoped>
  .card-login {
    border: 4px solid var(--el-border-color-base);
    box-shadow: 0px 0px 0px 10px white;
    border-color: black;
    padding-top: 50px;
    background: white;
    width: 250px;
    height: 550px;
  }
  .box-form {
    margin: 100px 0;
  }

  .el-input input {
    border-color: red;
  }

  .el-form .el-row {
    margin: 10px 0 ;
  }

  .input-label {
    float: left;
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }

  .logo {
    text-align: center;
    height: 80px;
  }
</style>

<script>
import { httpsCallable } from "firebase/functions";
import { functionsApp } from "../main.js";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

const actionCodeSettings = {
    url: 'http://localhost:5000/verifylogin',
    handleCodeInApp: true
  };

export default {
  data() {
    const checkMail = (rule, value, callback) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(value).toLowerCase()))
        callback()
      else
        callback(new Error('Por favor, insira um e-mail válido!'))
    }
    return {
      formError: false,
      formWait: false,
      linkSended: false,
      loginForm: {
        mail: '',
      },
      rules: {
        mail: [{ validator: checkMail, trigger: 'blur' }],
      },
    }
  },
  methods: {

    submitForm() {
      this.formError = false;
      this.linkSended = false;
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          console.log('submit!')
          console.log(this.$refs['loginForm']["model"].mail)
          const email = this.$refs['loginForm']["model"].mail
          const getConfigs = httpsCallable(functionsApp, 'magrathea-configs')
          getConfigs({
            resource: "check_user_exists",
            user: email
          }).then((result) => {
            const data = result.data;
            if (data["status"]) {
              console.log("CALL METHOD SEND LINK")
              // sendLinkSignIn(email)
              const auth = getAuth();
              sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                  // The link was successfully sent. Inform the user.
                  // Save the email locally so you don't need to ask the user for it again
                  // if they open the link on the same device.
                  window.localStorage.setItem('emailForSignIn', email);
                  // ...
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  // ...
                })

              console.log("END CALL METHOD SEND LINK")
              this.linkSended = true;
            } else
              this.formError = false;

          }).catch((result) => {
            console.log("ERROR ::::::::::::::")
            console.log(result)
            this.formError = true;
          })
        } else {
          console.log('error submit!!');
          return false
        }
      })
    },
  },
}
</script>
